import { legacy_createStore as createStore } from 'redux'
import moment from 'moment'

const initialState = {
  sidebarShow: false,
  theme: 'light',
  selectedChartGroup: 'Constituency Level Analytics',
  isAuthenticated: false,
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    case 'setSelectedChartGroup':
      return {
        ...state,
        selectedChartGroup: rest.selectedChartGroup,
      }
    default:
      return state
  }
}

const store = createStore(changeState)
export default store
