import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import checkToken from './CheckToken'
import supabase from '../../supabase'

const AuthWrapper = ({ children }) => {
  const isAuthenticated = useSelector((state) => state.isAuthenticated)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    supabase.auth
      .getUser()
      .then(({ data, error }) => {
        if (!error) {
          dispatch({ type: 'set', isAuthenticated: true })
          navigate('/dashboard')
        } else {
          dispatch({ type: 'set', isAuthenticated: false })
          navigate('/login')
        }
      })
      .catch((error) => {
        console.error('Error fetching user:', error)
      })
  }, [dispatch, navigate])

  return children
}

export default AuthWrapper
