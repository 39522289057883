import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import 'core-js'

import App from './App'
import store from './store'

import * as Sentry from '@sentry/react'

Sentry.init({
  dsn: 'https://346678b9d8d98f21ae02a8ea5e9e79f7@o4507140236902400.ingest.de.sentry.io/4507711797788752',
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  enabled: import.meta.env.MODE === 'production',
  environment: import.meta.env.MODE,
  release: __APP_VERSION__,
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/electo\.parivartan\.digital/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

createRoot(document.getElementById('root')).render(
  <Provider store={store}>
    <App />
  </Provider>,
)
